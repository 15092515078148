// eslint-disable-next-line no-shadow
enum Status {
    SUBMMITED = 'SUBMMITED',
    TEMP_SUBMITTED = 'TEMP_SUBMITTED',
    STARTED = 'STARTED',
    EMPTY_INPUT = 'EMPTY_INPUT',
    NOT_EXISTING = 'NOT_EXISTING',
    INITIAL = 'INITIAL',
    NETWORK_ERROR = 'NETWORK_ERROR',
    TRY_AGAIN = 'TRY_AGAIN'
  }

export { Status as default };
