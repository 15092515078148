<template>
  <div v-loading="audio.waiting" class="di main-wrap">
    <audio ref="audio" class="dn"
           :src="url" :preload="audio.preload"
           @play="onPlay"
           @error="onError"
           @waiting="onWaiting"
           @pause="onPause"
           @timeupdate="onTimeupdate"
           @loadedmetadata="onLoadedmetadata"
    />
    <h2>
      <span @click="startPlayOrPause">
        <i v-if="!audio.playing" class="bi bi-play-circle" />
        <i v-else class="bi bi-pause-circle" />
      </span>

      <span class="badge bg-secondary" style="margin-left: 5px; color: white;">
        <!-- {{ $t('播放進度：') }} -->
        {{ audio.currentTime | formatSecond }}</span>

      <h6 style="margin-left: 5px; color: grey; display:inline-block;">
        {{ $t('時長：') }}{{ audio.maxTime | formatSecond }}
      </h6>

      <button v-show="!controlList.noMuted" type="button" class="btn btn-outline-primary btn-sm" @click="startMutedOrNot">
        {{ audio.muted | transMutedOrNot }}
      </button>
    </h2>
  </div>
</template>

<script>

function realFormatSecond(second) {
  const secondType = typeof second;

  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second, 0);

    const hours = Math.floor(second / 3600);
    second -= hours * 3600;
    const mimute = Math.floor(second / 60);
    second -= mimute * 60;

    return `${(`0${mimute}`).slice(-2)}:${(`0${second}`).slice(-2)}`;
  }
  return '00:00';
}

export default {
  name: 'VueAudio',
  filters: {
    formatSecond(second = 0) {
      return realFormatSecond(second);
    },
    transPlayPause(value) {
      return value ? '暫停' : '播放';
    },
    transMutedOrNot(value) {
      return value ? '放音' : '靜音';
    },
    transSpeed(value) {
      return `快進: x${value}`;
    },
  },
  props: {
    theUrl: {
      type: String,
      required: true,
    },
    theSpeeds: {
      type: Array,
      default() {
        return [1, 1.5, 2];
      },
    },
    theControlList: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      url: this.theUrl || '',
      audio: {
        currentTime: 0,
        maxTime: 0,
        playing: false,
        muted: false,
        speed: 1,
        waiting: true,
        preload: 'auto',
      },

      sliderTime: 0,
      volume: 100,
      speeds: this.theSpeeds,

      controlList: {
        // download button is invisible
        noDownload: false,
        // mute button is invisible
        noMuted: false,
        // volume bar is invisible
        noVolume: false,
        // progress bar is invisible
        noProcess: false,
        // play only one
        onlyOnePlaying: false,
        // the button of do not speed up
        noSpeed: false,
      },
    };
  },
  created() {
    this.setControlList();
  },
  methods: {
    setControlList() {
      const controlList = this.theControlList.split(' ');
      controlList.forEach((item) => {
        if (this.controlList[item] !== undefined) {
          this.controlList[item] = true;
        }
      });
    },
    changeSpeed() {
      const index = this.speeds.indexOf(this.audio.speed) + 1;
      this.audio.speed = this.speeds[index % this.speeds.length];
      this.$refs.audio.playbackRate = this.audio.speed;
    },
    startMutedOrNot() {
      this.$refs.audio.muted = !this.$refs.audio.muted;
      this.audio.muted = this.$refs.audio.muted;
    },
    // volume bar - toolTip
    formatVolumeToolTip(index) {
      return `音量: ${index}`;
    },
    // progress bar - toolTip
    formatProcessToolTip(index = 0) {
      index = parseInt((this.audio.maxTime / 100) * index, 0);
      return `進度條: ${realFormatSecond(index)}`;
    },
    // change volume
    changeVolume(index = 0) {
      this.$refs.audio.volume = index / 100;
      this.volume = index;
    },
    //
    changeCurrentTime(index) {
      this.$refs.audio.currentTime = parseInt((index / 100) * this.audio.maxTime, 0);
    },
    startPlayOrPause() {
      return this.audio.playing ? this.pausePlay() : this.startPlay();
    },
    //
    startPlay() {
      this.$refs.audio.play();
    },
    // pause
    pausePlay() {
      this.$refs.audio.pause();
    },
    //
    onPause() {
      this.audio.playing = false;
    },
    //
    onError() {
      this.audio.waiting = true;
    },
    //
    onWaiting(res) {
      console.log(res);
    },
    //
    onPlay(res) {
      console.log(res);
      this.audio.playing = true;
      this.audio.loading = false;

      if (!this.controlList.onlyOnePlaying) {
        return;
      }

      const { target } = res;

      const audios = document.getElementsByTagName('audio');

      [...audios].forEach((item) => {
        if (item !== target) {
          item.pause();
        }
      });
    },
    onTimeupdate(res) {
      // console.log('timeupdate')
      // console.log(res)
      this.audio.currentTime = res.target.currentTime;
      this.sliderTime = parseInt((this.audio.currentTime / this.audio.maxTime) * 100, 0);
    },

    onLoadedmetadata(res) {
      console.log('loadedmetadata');
      console.log(res);
      this.audio.waiting = false;
      this.audio.maxTime = parseInt(res.target.duration, 0);
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main-wrap{
    padding: 10px 15px;
  }
  .slider {
    display: inline-block;
    width: 100px;
    position: relative;
    top: 14px;
    margin-left: 15px;
  }

  .di {
    display: inline-block;
  }

  .download {
    color: #409EFF;
    margin-left: 15px;
  }

  .dn{
    display: none;
  }

</style>
