export interface QuestionModel {
    userAnswer: String[];
    sourceQuiz: unknown;
    uuid: string;
    timeSpent: number;
    multipleSelect: boolean;
    blankFillAnswer: String;
}

export class Question implements QuestionModel {
    userAnswer = [] as String[];

    sourceQuiz = {} as string | JSON;

    uuid = '';

    timeSpent = 0;

    multipleSelect = false;

    blankFillAnswer = '';

    constructor(userAnswer: [], sourceQuiz: string | JSON, uuid: string, timeSpent: number, multipleSelect: boolean) {
      if (userAnswer === null || userAnswer === undefined) {
        this.userAnswer = [];
      } else {
        this.userAnswer = userAnswer;
      }
      this.sourceQuiz = sourceQuiz;
      this.uuid = uuid;
      this.timeSpent = timeSpent;
      this.multipleSelect = multipleSelect;
    }
}
