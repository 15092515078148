
























import Vue from 'vue';
/**
 * Usage:
 * <TextArea v-model="inputValue" />
 *
 * TextArea implemented two-way data binding on the input value.
 */
export default Vue.extend({
  name: 'TextArea',
  props: {
    value: {
      type: String,
      default: '',
    },
    displayPlaceholder: {
      type: Boolean,
      default: true,
    },
    field: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  watch: {
    /**
     * Triggered in two contexts:
     * 1. value is changed by input element used by users
     * 2. value is changed by program (api data update value, reset value and etc.)
     *
     * In context 1, display element already have the up-to-date value,
     * so there is no need to update display value with newValue (display value === newValue in this case).
     * On the other hand, we should update the value in deplay element when input source of the newValue is program (context 2),
     * (display value !== newValue in this case)
     */
    value(newValue) {
      const editable = this.$refs.editable as HTMLDivElement;
      if (!editable) return;

      // a hack:
      // only update value while the new value source is from program (not from inputElement)
      if (newValue !== editable.innerText) {
        // update value
        editable.innerText = newValue;

        // if user is currently focusing the input element,
        // the input cursor would be set to the begining (which is confusing)
        // So, move input cursor to the end (last char of lastline)
        const range = document.createRange();
        const sel = window.getSelection();
        if (!sel) {
          throw new Error('Get selection failed');
        }

        const lastLine = editable.childNodes[editable.childNodes.length - 1] as Text;
        if (!lastLine) return;
        range.setStart(lastLine, lastLine.length);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
  },
  mounted() {
    const editable = this.$refs.editable as HTMLDivElement;
    if (!editable) return;

    editable.innerText = this.value;
  },
  methods: {
    onInput(e: { target: HTMLDivElement }) {
      this.$emit('input', e.target.innerText);
    },
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    reset() {
      this.$emit('input', '');
    },
  },
});
