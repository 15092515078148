











import Vue, { PropType } from 'vue';
import TextArea from '@/components/stage/TextArea.vue';
import { StageEventBus } from '@/libs/EventBus';
import { TextField } from '@/apis/models/QuizModel';

export default Vue.extend({
  name: 'StageText',
  components: {
    TextArea,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scaleRatio: {
      type: Number,
      required: true,
    },
    field: {
      type: Object as PropType<TextField>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      displayPlaceholder: true,
    };
  },
  computed: {
    textScaleRatio(): number {
      // By default, text size is designed for QuizMaker.stageWidth=300 and is not scalable
      // magicRatio is the scale ratio when QuizMaker.stageWidth=300
      const magicRatio = 0.28846153846153844;
      return this.scaleRatio / magicRatio;
    },
  },
  mounted() {
    StageEventBus.$on('reset-stage', this.reset);
    StageEventBus.$on('before-screenshot', this.beforeScreenshot);
    StageEventBus.$on('after-screenshot', this.afterScreenshot);
  },
  methods: {
    reset() {
      // reset data here
    },
    beforeScreenshot() {
      this.displayPlaceholder = false;
    },
    afterScreenshot() {
      this.displayPlaceholder = true;
    },
  },
});
