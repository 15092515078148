export interface Onboarding {
    hasOnboarding: boolean;
}

export interface OnboardingPayload {
  hasOnBoarding: String; // 'True' or 'False'
}

export class OnboardingModel implements Onboarding {
    hasOnboarding = true;

    constructor(payload: OnboardingPayload) {
      if (payload.hasOnBoarding === 'False') {
        this.hasOnboarding = false;
      } else {
        this.hasOnboarding = true;
      }
    }
}
