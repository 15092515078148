




















import { ToastPlugin } from 'bootstrap-vue';
import Vue from 'vue';

Vue.use(ToastPlugin);

export default Vue.extend({
  methods: {
    callSubmit() {
      this.$emit('callSubmit');
    },
  },
});

