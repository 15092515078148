






import Vue from 'vue';
import Stage from '@/components/stage/Stage.vue'; // @ is an alias to /src

export default Vue.extend({
  name: 'Home',
  components: {
    Stage,
  },
  data() {
    return {
      // mock quiz data
      quizData: {
        answer: 'B',
        templateName: 'title four image',
        clickAreas: [
          {
            area: {
              x: 0,
              width: 1040,
              y: 0,
              height: 520,
            },
            contents: [
              {
                options: [
                  {
                    inputType: 'number',
                    label: '大小',
                    value: '22',
                  },
                ],
                label: '文字',
                check: true,
                type: 'text',
                value: '什麼顏色？',
              },
              {
                options: [
                  {
                    inputType: 'number',
                    label: '尺寸',
                    value: '125',
                  },
                ],
                label: '圖片',
                check: true,
                type: 'image',
                value: 'https://storage.googleapis.com/quiz-storage/images/QMY1SCYYxJbnoK5',
              },
            ],
            action: {
              type: '',
            },
            label: 'Title',
          },
          {
            area: {
              x: 0,
              width: 520,
              y: 520,
              height: 520,
            },
            contents: [
              {
                options: [
                  {
                    inputType: 'number',
                    label: '大小',
                    value: 24,
                  },
                ],
                label: '文字',
                check: false,
                type: 'text',
                value: '',
              },
              {
                options: [
                  {
                    inputType: 'number',
                    label: '尺寸',
                    value: '108',
                  },
                ],
                label: '圖片',
                check: true,
                type: 'image',
                value: 'https://storage.googleapis.com/quiz-storage/images/kikxTIP59rAa42k',
              },
            ],
            action: {
              type: 'answer',
            },
            label: 'A',
          },
          {
            area: {
              x: 520,
              width: 520,
              y: 520,
              height: 520,
            },
            contents: [
              {
                options: [
                  {
                    inputType: 'number',
                    label: '大小',
                    value: 24,
                  },
                ],
                label: '文字',
                check: false,
                type: 'text',
                value: '',
              },
              {
                options: [
                  {
                    inputType: 'number',
                    label: '尺寸',
                    value: '168',
                  },
                ],
                label: '圖片',
                check: true,
                type: 'image',
                value: 'https://storage.googleapis.com/quiz-storage/images/baRAjqhUBU19cp4',
              },
            ],
            action: {
              type: 'answer',
            },
            label: 'B',
          },
          {
            area: {
              x: 0,
              width: 520,
              y: 1040,
              height: 520,
            },
            contents: [
              {
                options: [
                  {
                    inputType: 'number',
                    label: '大小',
                    value: 24,
                  },
                ],
                label: '文字',
                check: false,
                type: 'text',
                value: '',
              },
              {
                options: [
                  {
                    inputType: 'number',
                    label: '尺寸',
                    value: '178',
                  },
                ],
                label: '圖片',
                check: true,
                type: 'image',
                value: 'https://storage.googleapis.com/quiz-storage/images/fsUxDMq7NPBm32T',
              },
            ],
            action: {
              type: 'answer',
            },
            label: 'C',
          },
          {
            area: {
              x: 520,
              width: 520,
              y: 1040,
              height: 520,
            },
            contents: [
              {
                options: [
                  {
                    inputType: 'number',
                    label: '大小',
                    value: 24,
                  },
                ],
                label: '文字',
                check: false,
                type: 'text',
                value: '',
              },
              {
                options: [
                  {
                    inputType: 'number',
                    label: '尺寸',
                    value: '178',
                  },
                ],
                label: '圖片',
                check: true,
                type: 'image',
                value: 'https://storage.googleapis.com/quiz-storage/images/IgB23kRMQHjs2Vz',
              },
            ],
            action: {
              type: 'answer',
            },
            label: 'D',
          },
        ],
        baseImage: {
          width: 1040,
          url: 'https://i.imgur.com/N3Yb1E6.png',
          height: 1560,
        },
        outputImage: {
          width: 1040,
          url: 'https://storage.googleapis.com/quiz-storage/images/XnofHElxdVOH5Pe',
          height: 1560,
        },
        templateId: '1234a7b6-78f0-465b-a6bb-fa02df2c24b8',
        enableAudio: true,
        audioUrl: 'https://storage.googleapis.com/quiz-storage/audios/pGh2qtxGn6YuRqt.m4a',
        audioDuration: 27252,
        uuid: 'a9a966e3-cd2d-43cf-bb1f-992de3a47e64',
        previewImage: {
          width: 1040,
          url: 'https://storage.googleapis.com/quiz-storage/images/XnofHElxdVOH5Pe',
          height: 1560,
        },
      },
    };
  },
});
