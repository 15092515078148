import { render, staticRenderFns } from "./StageBlock.vue?vue&type=template&id=5ba58848&scoped=true&"
import script from "./StageBlock.vue?vue&type=script&lang=ts&"
export * from "./StageBlock.vue?vue&type=script&lang=ts&"
import style0 from "./StageBlock.vue?vue&type=style&index=0&id=5ba58848&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ba58848",
  null
  
)

export default component.exports